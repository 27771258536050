import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import BackgroundImage from "gatsby-background-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../styles/application-lifecycle.scss"
import "../styles/services.scss"
import { GiCycle } from "react-icons/gi"
import { MdTransform } from "react-icons/md"
import { FaWrench } from "react-icons/fa"
import { BiSupport } from "react-icons/bi"

import ButtonB from "../components/ButtonB"

//Images
import Divider from "../images/Blue-line.png"
import { SiHomeassistant } from "react-icons/si"
import { ImCogs } from "react-icons/im"
import { GoGraph } from "react-icons/go"
import { FaCircle, FaRegCircle } from "react-icons/fa"
import MapImg from "../images/map.png"
import LogoCarousel from "../components/LogoCarousel"
import ScrollAnimation from "react-animate-on-scroll"
import ContactForm from "../components/ContactForm"
import TextAccordion from "../components/TextAccordion/TextAccordion"
import Diagram from "../components/Diagram"
import GartnerDiagram from "../components/GartnerDiagram"

import Cisco from "../images/cisco.png"

//data for accordion

//strategic
const strategic = [
  {
    icon: "",
    title: "Summary",
    content:
      "Our strategic advisory and consultancy services help customers align their IT transformation initiatives to their business goals.  With our blueprint reference model we help customers create a holistic, cohesive and structured roadmap to application modernisation and achieve a level of digital maturity that matches the vision of the business. Through workshops, structured assessments, and data-driven insights, we help identify capability gaps in transformation strategies;, define enabling service architectures that align to the business;, and develop roadmaps that provide an accelerated path to maturity.",
  },
  {
    title: "Value to Our Customers",
    content:
      "Clarifying the vision for transformation leads to cost-efficient, coordinated, cross-organisation investment strategies, and reduces operational overheads associated with project sprawl.  It empowers our customers with a clear understanding of the most important first, or next steps, and which components of their transformation programs to prioritise.",
  },
  {
    title: "Use Case",
    content:
      "Metsi conducted an infrastructure discovery and assessment of our customer’s data centres. The high-level objectives were to gain an understanding of the infrastructure, assess against standard industry and best practice design, and provide a high-level assessment of it being fit-for-purpose for our customers’ business applications in the near to medium term.  This strategic assessment also identified areas of key risks and key opportunities that the customer should focus on.",
  },
]

//transformation
const transformation = [
  {
    icon: "",
    title: "Summary",
    content:
      "Our Metsi-lead transformation services accelerate the customer’s journey to application modernisation and digital maturity. Using a Client-approved roadmap focused on business outcomes, Metsi rapidly and iteratively builds and operates new hybrid cloud and automation services, then transfers capability to the customer when it best suits them. The technology stack of choice can be tailored to best integrate with existing customer solutions and tools, with Metsi's vendor-agnostic approach and recommendations identifying and address capability gaps cost-effectively.",
  },
  {
    title: "Value to Our Customers",
    content:
      "Our specialist teams, with proven experience across the hybrid-cloud stack and an established development methodology, deliver working solutions faster and cheaper.  This means our customers can focus on creating business value through service innovation and transitioning operating modes faster, rather than platform and process development. True value is realised in getting new products and services to market faster, and recognising operational efficiencies sooner.",
  },
  {
    title: "Use Case",
    content:
      "Metsi has delivered a hybrid cloud solution aligned with the consolidated vision, business drivers and organisational goals based on a scalable technical architecture. Metsi provided a strategic hybrid cloud solution roadmap to support the customer’s transformation journey into hybrid cloud maturity.",
  },
]

//engineering
const engineering = [
  {
    icon: "",
    title: "Summary",
    content:
      "Metsi’s flexible and customisable digital engineering services cover infrastructure design and delivery, automation code development, and full-stack integration. Under the guidance of our customers’ transformation programs and projects we can provide experienced resources for advisory, consulting and development services.",
  },
  {
    title: "Value to Our Customers",
    content:
      "Customers leverage Metsi's specialist skills base to cost effectively fill engineering gaps, reduce the time-to-value from platform builds, accelerate service adoption, or just to provide assurance to business outcomes. Our customisable knowledge transfer offerings also mean your business is ready to run when you need to. Metsi provides a flexible engagement model, working to Agile delivery practices or aligned to customer methodologies, to focus on outcomes and customer value. This ensures the customer has the rights skills available, at the right time and for the right value.",
  },
  {
    title: "Use Case",
    content:
      "Metsi has implemented a unified self-service IT portal experience that increases user satisfaction in interacting and receiving services from the IT organisation. By enabling ITaaS creation and delivery, Metsi facilitates cross departmental automation that is standardised and repeatable. The time and effort taken to deliver services is greatly reduced, and a consistent services experience is assured.",
  },
]

//support
const support = [
  {
    icon: "",
    title: "Summary",
    content:
      "A range of design, development and operational support services ensure the smooth running and ongoing maturing of customer solutions. Our foundation tiers of services can be tailored to the right skill sets, delivery models and service contracts to meet the needs of each customer.",
  },
  {
    title: "Value to Our Customers",
    content:
      "The investments our customers have made in IT should deliver what their business needs today, and scale as their business grows. Metsi’s flexible support services are tailored to our customers’ requirements, to provide on-going enhancement, support and continuous service improvement.",
  },
  {
    title: "Use Case",
    content:
      "HealthCheck - our regular lightweight service to maintain the health, performance and security of the entire solution stack improving service uptime and customer digital experience, and addressing data compliance requirements. HealthCare - engineering and development support to evolve the solution and services with changing business needs, including software dependency and lifecycle management, creating business and service agility. MetsiCare - our world-class consultancy support for continuous improvement and optimisation services to ensure ongoing maximum return on investment in a changing business landscape.",
  },
]

const Services = () => {
  //gatsby-image query
  const applicationData = useStaticQuery(graphql`
    query applicationImages2 {
      hero2: file(relativePath: { eq: "hero-3.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      canopy2: file(relativePath: { eq: "moss.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      sky2: file(relativePath: { eq: "drops.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO
        title="Services"
        description="Metsi hosts a range of services in cloud computing, including strategic consultancy, transformation, engineering and support services. Metsi’s partners include Cloudbolt Software and Cisco."
        keywords={[
          "digital transformation consultancy services",
          "it infrastructure services",
          "it infrastructure management services",
          "cloud infrastructure engineer professional services",
          "devops professional services",
          "enterprise infrastructure as a service",
          "vmware infrastructure as a service",
          "consulting platform as a service",
          "everything as a service",
          "anything as a service",
          "application management services",
          "application modernisation services",
          "legacy application modernisation services",
        ]}
        lang="en-gb"
      />
      {/********Hero Section *********/}
      <BackgroundImage
        fluid={applicationData.hero2.childImageSharp.fluid}
        className="application-hero"
        style={{
          width: "100vw",
          backgroundPosition: "bottom",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="hero-content">
          <div className="transparent">
            <div className="outer-container">
              <div className="row">
                <div className="inner-container-2 left">
                  <div className="droplet-blue">
                    <FaWrench className="icon" />
                  </div>
                </div>
                <div className="inner-container-2 right">
                  <div className="column">
                    <h1 style={{ color: "#103144" }}>Services</h1>
                    <img src={Divider} alt="divider" className="divider" />
                    <p>
                      Our engagement methodology is simple and we approach
                      complex problems with a fresh pair of eyes.
                    </p>
                    <p style={{ color: "#626366" }}>
                      Our investment in the technical architecture team has been
                      a recipe of differentiation and a catalyst for securing
                      critical long-term contracts and new customers. The
                      ability to lead with Professional Services has also
                      allowed us to become a confident and trusted adviser to
                      many of our customers and ensures that our reach goes
                      deeper and wider across the customers IT Landscape.
                    </p>

                    {/* <ButtonB href="#services">Learn More</ButtonB> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </BackgroundImage>
      {/********* Services ***********/}
      <div className="section services" id="services">
        <div className="inner-container">
          <h2>Services</h2>
          <h3>
            Improved Collaboration, Faster Software Delivery, Minimal Rework
          </h3>
          <p>
            Metsi’s Application Management Services (AMS) provide both a
            reactive and proactive approach to your applications. Reactively, we
            give you a strategic view of your IT systems for incident
            management, service response times and more. Proactively, we deliver
            solutions for release management, change management, and operational
            management.
          </p>
        </div>
        <div className="row" style={{ maxWidth: "1400px" }}>
          <div className="inner-container-3" style={{ maxWidth: "180px" }}>
            <a href="#api-interoperability">
              <div className="droplet-blue">
                <GoGraph className="icon" />
              </div>
            </a>
            <a href="#api-interoperability">
              <h2 className="droplet-header" style={{ color: "#000" }}>
                Strategic Services
              </h2>
            </a>
          </div>

          <div className="inner-container-3" style={{ maxWidth: "180px" }}>
            <a href="#transformation">
              <div className="droplet-blue">
                <MdTransform className="icon" />
              </div>
            </a>
            <a href="#transformation">
              <h2 className="droplet-header" style={{ color: "#000" }}>
                Transformation Services
              </h2>
            </a>
          </div>

          <div className="inner-container-3" style={{ maxWidth: "180px" }}>
            <a href="#engineering">
              <div className="droplet-blue">
                <ImCogs className="icon" />
              </div>
            </a>
            <a href="#engineering">
              <h2 className="droplet-header" style={{ color: "#000" }}>
                Engineering Services
              </h2>
            </a>
          </div>

          <div className="inner-container-3" style={{ maxWidth: "180px" }}>
            <a href="#support">
              <div className="droplet-blue">
                <BiSupport className="icon" />
              </div>
            </a>
            <a href="#support">
              <h2 className="droplet-header" style={{ color: "#000" }}>
                Support Services
              </h2>
            </a>
          </div>

          {/* <div className="inner-container-3">
            <a href="#fullstack">
              <div className="droplet-blue">
                <ImCogs className="icon" />
              </div>
            </a>
            <a href="#fullstack">
              <h2 style={{ color: "#000" }}>XaaS</h2>
            </a>
          </div> */}
        </div>
      </div>

      {/*********** API Interoperability *************/}
      <BackgroundImage
        fluid={applicationData.sky2.childImageSharp.fluid}
        className="section"
        id="strategic"
        style={{
          width: "100vw",
          minHeight: "70vh",
          backgroundPosition: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "#fff",
        }}
      >
        <div className="overlay section">
          <div className="outer-container">
            <div className="inner-container">
              <h2>Services</h2>
              <h3>Strategic</h3>
            </div>
          </div>
          <TextAccordion panels={strategic} />
        </div>
      </BackgroundImage>

      {/*********** Transformation *************/}
      <div className="section" id="transformation">
        <div className="overlay section">
          <div className="outer-container">
            <div className="inner-container">
              <h2>Services</h2>
              <h3>Transformation</h3>
            </div>
          </div>
          <TextAccordion panels={transformation} />
        </div>
      </div>

      {/********* Engineering ***********/}
      <BackgroundImage
        fluid={applicationData.canopy2.childImageSharp.fluid}
        className="section"
        id="engineering"
        style={{
          width: "100vw",
          minHeight: "80vh",
          backgroundPosition: "top",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "#fff",
        }}
      >
        <div className="overlay section">
          <div className="outer-container">
            <div className="inner-container">
              <h2>Services</h2>
              <h3>Engineering</h3>
            </div>
          </div>
          <TextAccordion panels={engineering} />
        </div>
      </BackgroundImage>

      {/*********** Support *************/}
      <div className="section" id="support">
        <div className="overlay section">
          <div className="outer-container">
            <div className="inner-container">
              <h2>Services</h2>
              <h3>Support</h3>
            </div>
          </div>
          <TextAccordion panels={support} />
        </div>
      </div>

      {/*************** Diagram  ****************/}
      {/* <Diagram /> */}
      {/*************** SVG Diagram  ****************/}

      {/* <div className="section">
        <GartnerDiagram
          style={
            {
              width: "100%" 
            }
          }
        />
      </div> */}

      {/*********** Partners *************/}
      <div className="section partners">
        <div className="outer-container" style={{ display: "block" }}>
          <div
            className="droplet-blue-invert"
            style={{ marginTop: "25px", float: "left" }}
          >
            Our Partners
          </div>
          <div style={{ display: "block" }}>
            <LogoCarousel />
          </div>
        </div>
      </div>
      {/*********** Contact *************/}
      <div className="contact-section" id="contact">
        <div className="outer-container">
          <div className="contact-header">
            <h2>Contact Us</h2>
            <ScrollAnimation animateIn="animate__fadeInUp" duration="1.3">
              <h1 style={{ textAlign: "center" }}>
                Have a question about our solutions?
              </h1>
            </ScrollAnimation>
          </div>
          <div className="row">
            <div className="inner-container-2">
              <ContactForm />
            </div>
            <div className="inner-container-2">
              <div className="row">
                <div className="inner-container">
                  <img src={MapImg} alt="map" style={{ width: "100%" }} />
                </div>
              </div>
              <div className="row">
                <div
                  className="inner-sub-container-2"
                  style={{
                    alignItems: "flex-start",
                    textAlign: "left",
                    padding: "40px 50px",
                  }}
                >
                  <h4 style={{ color: "#21B7E0" }}>Office Locations</h4>
                  <ul>
                    <li>
                      <FaCircle className="icon" id="uk" />
                      UK
                    </li>
                    <li>
                      <FaCircle className="icon" id="usa" />
                      USA
                    </li>
                    <li>
                      <FaCircle className="icon" id="germany" />
                      Germany
                    </li>
                    <li>
                      <FaCircle className="icon" id="netherlands" />
                      The Netherlands
                    </li>
                    <li>
                      <FaCircle
                        className="icon"
                        id="india"
                        style={{ color: "transparent" }}
                      />
                    </li>
                  </ul>
                </div>
                <div
                  className="inner-sub-container-2"
                  style={{
                    alignItems: "flex-start",
                    textAlign: "left",
                    padding: "40px 50px",
                  }}
                >
                  <h4 style={{ color: "#21B7E0" }}>Satellite Locations</h4>
                  <ul>
                    <li>
                      <FaRegCircle className="icon" id="belgium" />
                      Belgium
                    </li>
                    <li>
                      <FaRegCircle className="icon" id="sa" />
                      South Africa
                    </li>
                    <li>
                      <FaRegCircle className="icon" id="poland" />
                      Poland
                    </li>
                    <li>
                      <FaRegCircle className="icon" id="spain" />
                      Spain
                    </li>
                    <li>
                      <FaRegCircle className="icon" id="india" />
                      India
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Services
